import React from 'react';
import ContactForm from './ContactForm';
import config from '../../config';
import logo from "../assets/img/logo.png"
import {Link} from "gatsby";

export default function Footer() {
    return (
        <footer id="footer">
            <div className="inner">
                <section>
                    <h2>Information</h2>
                    <img src={logo} alt="logo" style={{width: 180}}/>
                    <dl style={{paddingTop: 10}}>
                        <dd style={{margin: 0}}>admin@audaco.com</dd>
                        <dd style={{margin: 0}}>+52 55 5344 6775</dd>
                        <dd style={{margin: 0}}>Horario 9:00–18:00 HRS</dd>
                    </dl>
                </section>
                <section>
                    <h2>Social media</h2>
                    <ul className="icons">
                        {config.socialLinks.map(social => {
                            const {icon, name, url} = social;
                            return (
                                <li key={url}>
                                    <a href={url} className={`icon ${icon}`}>
                                        <span className="label">{name}</span>
                                    </a>
                                </li>
                            );
                        })}
                    </ul>
                </section>
                <section>
                    <h2>Help</h2>
                    <dl style={{paddingTop: 10}}>
                        <dd style={{margin: 0}}><
                            Link to="/About">More information</Link>
                        </dd>
                        <dd style={{margin: 0}}><
                            Link to="/Contact">Contact</Link>
                        </dd>
                    </dl>
                </section>
                <ul className="copyright">
                    <li>&copy; 2021 Audaco</li>
                </ul>
            </div>
        </footer>
    );
}
