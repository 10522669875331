import React from "react";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome"
import {faWhatsapp} from "@fortawesome/free-brands-svg-icons"

const WhatsApp = () => (
    <a href="https://wa.me/525553446775?text=Servicios especializados en tecnología"
       className="whatsapp"
       target="_blank">
        <FontAwesomeIcon icon={faWhatsapp}/>
    </a>

);

export default WhatsApp;
